// 提交申请
const applyUrl = `/gateway/hc-edu/shuttleBusOrder/apply`;
// 获取上次提交信息
const getLastOrderInfoUrl = `/gateway/hc-edu/shuttleBusOrder/getLastOrderInfo`;
// 修改连续报名状态
const changeStatusUrl = `/gateway/hc-edu/shuttleBusOrder/changeStatus`;
// 获取签到信息列表
const getSignInfoUrl = `/gateway/hc-edu/shuttleBusOrder/getSignInfo`;
// 签到
const changeSignUrl = `/gateway/hc-edu/shuttleBusOrder/changeSign`;
// 获取当前日期的班次信息
const getBusFrequencyUrl = `/gateway/hc-edu/shuttleBusOrder/getBusFrequency`;
// 取消报名
const cancelApplyUrl = `/gateway/hc-edu/shuttleBusOrder/cancelApply`;
// 修改批次
const changeBatchUrl = `/gateway/hc-edu/shuttleBusOrder/changeBatch`;
// 重新报名
const applyAgainUrl = `/gateway/hc-edu/shuttleBusOrder/applyAgain`;
// 删除临时添加
const delRecordsByIdUrl = `/gateway/hc-edu/shuttleBusOrder/delRecordsById`;
// 临时添加
const temporaryAddUrl = `/gateway/hc-edu/shuttleBusOrder/temporaryAdd`;
export {
  applyUrl,
  getLastOrderInfoUrl,
  changeStatusUrl,
  getSignInfoUrl,
  changeSignUrl,
  getBusFrequencyUrl,
  cancelApplyUrl,
  changeBatchUrl,
  applyAgainUrl,
  delRecordsByIdUrl,
  temporaryAddUrl,
};
