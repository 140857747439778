<template>
  <div class="page">
    <div class="item">
      <div class="item-l">学生姓名</div>
      <div class="item-r">
        <input
          v-model="childrenName"
          type="text"
          placeholder="请输入学生姓名"
        />
      </div>
    </div>
    <div class="item">
      <div class="item-l">所在班级</div>
      <div class="item-r">
        <input
          v-model="childrenClass"
          type="text"
          placeholder="请输入所在班级"
        />
      </div>
    </div>
    <div class="item">
      <div class="item-l">选择班次</div>
      <div class="item-r">
        <div class="batcbBox" @click="show = true">
          {{ busFrequencyStr || "请选择班次" }}
        </div>
      </div>
    </div>
    <div class="submitBtn" @click="submit">保存</div>
    <v-picker
      :columns="busFrequencyList"
      :valueShow="show"
      valueKey="batchTime"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import { temporaryAddUrl, getBusFrequencyUrl } from "./api.js";
export default {
  name: "addStudySignInfo",
  data() {
    return {
      show: false,
      userInfo: {},
      busFrequencyList: [],
      childrenName: "",
      childrenClass: "",
      batchId: "",
      busFrequency: "",
      busFrequencyStr: "",
    };
  },
  async created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log(this.userInfo);
  },
  async mounted() {
    await this.getBusFrequencyList();
  },
  methods: {
    submit() {
      if (this.childrenName == "") {
        this.$toast("请输入学生姓名！");
        return;
      }
      if (this.childrenClass == "") {
        this.$toast("请输入所在班级！");
        return;
      }
      if (this.busFrequencyStr == "") {
        this.$toast("请选择班次！");
        return;
      }
      let params = {
        childrenName: this.childrenName,
        childrenClass: this.childrenClass,
        batchId: this.batchId,
        busFrequency: this.busFrequency,
        tenantId: this.userInfo.tenantId,
      };
      this.$axios
        .post(`${temporaryAddUrl}`, params, {
          isQinxue: true,
          headers: {
            "hc-auth": "bearer " + this.userInfo.accessToken,
            Authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast(res.msg);
            setTimeout(() => {
              this.$router.back();
            }, 400);
          }
        });
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      console.log(value);
      this.batchId = value.batchId;
      this.busFrequency = value.frequencyId;
      this.busFrequencyStr = value.batchTime;
      this.show = false;
    },
    async getBusFrequencyList() {
      let tenantId = this.userInfo.tenantId;
      let res = await this.$axios.get(
        `${getBusFrequencyUrl}?tenantId=${tenantId}`,
        {
          isQinxue: true,
          headers: {
            "hc-auth": "bearer " + this.userInfo.accessToken,
            Authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
          },
        }
      );
      if (res.code == 200) {
        this.busFrequencyList = [];
        res.data.forEach((item) => {
          if (item.batchTime != "当天不乘车") {
            this.busFrequencyList.push(item);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 30px 30px;
  box-sizing: border-box;
  background: #fff;
  .submitBtn {
    width: 400px;
    height: 80px;
    background: #007eff;
    border-radius: 48px;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
    line-height: 80px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
  }
  .item {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 28px 0 34px;
    box-sizing: border-box;
    line-height: 100px;
    border-bottom: 2px solid rgba(141, 138, 138, 0.1);
    .item-l {
      height: 100%;
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .item-r {
      flex: 1;
      height: 100%;
      font-size: 28px;
      .batcbBox {
        text-align: right;
        color: #757779;
      }
      input {
        width: 100%;
        text-align: right;
        height: 100%;
        background-color: transparent;
      }
    }
  }
}
</style>
